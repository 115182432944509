import React, { useState, useEffect } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from "gatsby";
import Geocode from "react-geocode";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

import * as classes from "./contact.module.scss";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ContactForm from './contact_form';

const mapStyles = [
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [
      {
        weight: "2.00",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#9c9c9c",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#7b7b7b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#46bcec",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#c8d7d4",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#070707",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
];

const Contact = () => {
  const {t} = useTranslation();
  const Cordinates = { lat: "48.883407", lng: "2.303809" };
  const googleApiKey = "AIzaSyCzenqp-CpBQ7DoL-R2UEoWF0kB5RIJvUM"


  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey:googleApiKey ,
  });

  const [cords, setCords] = useState({});

  const [address, setAddress] = useState("");

  useEffect(() => {
    setCords({ ...Cordinates });
  }, []);

  useEffect(() => {
    Geocode.setApiKey("AIzaSyCzenqp-CpBQ7DoL-R2UEoWF0kB5RIJvUM");
    Geocode.enableDebug();
    Geocode.fromLatLng(cords.lat, cords.lng).then(
      (response) => {
        setAddress(response.results[0].formatted_address);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }, [cords]);
  const breakpoints = useBreakpoint();

  const StringWithoutLastWord = (e) => {
    let str = e;
    let lastIndex = str.lastIndexOf(",");

    return str.substring(0, lastIndex);
  };
  return (
    <>
      <div className="font-sans bg-gray-50">
        <div
          className={`text-white relative bg-cover lg:bg-contain bg-no-repeat pb-0 ${classes.banner}`}
          style={{
            backgroundImage: `${
              breakpoints.l
                ? `url("https://d2lxkizvrhu4im.cloudfront.net/images/home_top_banner.svg")`
                : `url("https://d2lxkizvrhu4im.cloudfront.net/images/top_banner_backround.svg")`
            }`,
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <div className={`w-full lg:w-1/2 mx-auto md:mx-0 lg:mx-auto space-y-5 my-6`}>
              <div className={` text-sm md:text-base font-semibold text-center`}>
                {t('any_questions')}
              </div>
              <div
                className={`md:text-6xl text-center font-bold`}
                style={
                  breakpoints.l ? { fontSize: "40px" } : { fontSize: "60px" }
                }
              >
                <h1>{t('contact_us')}</h1>
              </div>
              <div className={`text-center w-10/12 md:w-11/12 text-sm md:text-xl mx-auto`}>
                {t('tell_us_what_we_can_do')}
              </div>
            </div>

            <div className={` w-11/12 lg:w-5/6 flex flex-col md:flex-row mx-auto box-border my-5 space-y-20 md:space-y-0 md:space-x-5`}>
              <div className={`w-full md:w-1/2 space-y-4 flex flex-col justify-between`}>
                <div className="flex flex-col  md:flex-row  p-8 md:p-6 bg-white rounded-xl shadow-lg md:space-x-8">
                  <div className="flex flex-row justify-center">
                    <img
                      alt="contact"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/briefcase_icon.svg"
                      className="h-10 lg:h-16"
                    />
                  </div>
                  <div className="w-full md:w-4/5 space-y-3">
                    <div className="text-xl font-bold text-black text-center md:text-left">
                      {t('sales')}
                    </div>
                    <div className="text-gray-500  text-sm md:text-base text-center font-medium md:text-left">
                      {t('contact_our_sales_team')}{" "}
                      <br className="hidden lg:block" /> {t('learn_more_about')}
                    </div>
                    <div className="flex flex-row text-sm md:text-base justify-center md:justify-start items-baseline mt-1 space-x-2">
                      {" "}
                      <div className="text-yellow-500 font-semibold">
                        <Link to="/demo">
                          {t('personalized_quote_and_demonstration')}
                        </Link>
                      </div>{" "}
                      <img
                        src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                        alt="arrow down icon"
                        className="transform -rotate-90 w-3 m-0 p-0 -translate-y-0.5"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="flex flex-col md:flex-row p-8 md:p-6 bg-white rounded-xl shadow-lg md:space-x-8"
                  style={{ minHeight: "180px" }}
                >
                  <div className="flex flex-row justify-center">
                    <img
                      alt="contact"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/note_icon.svg"
                      className="h-10 lg:h-16"
                    />
                  </div>

                  <div className="w-full md:w-4/5 space-y-3">
                    <div className="text-xl font-bold text-black text-center md:text-left">
                      {t('media_and_press')}
                    </div>
                    <div className=" text-gray-500  text-sm md:text-base text-center font-medium md:text-left">
                      {t('discover_our_news_and_the_elements')}{" "}
                      <br className="hidden lg:block" />{t('mereos_brand')}
                    </div>
                    <div className=" flex flex-row text-sm md:text-base justify-center md:justify-start items-baseline mt-1 space-x-2">
                      {" "}
                      <Link
                        to="https://mereos.eu/blog/newsroom"
                        className="text-yellow-500 font-semibold"
                      >
                        {t('press_room')}
                      </Link>{" "}
                      <img
                        src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                        alt="arrow down icon"
                        className="transform -rotate-90 w-3 m-0 p-0 -translate-y-0.5"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="flex flex-col  md:flex-row  p-8 md:p-6  bg-white rounded-xl shadow-lg md:space-x-8"
                  style={{ minHeight: "180px" }}
                >
                  <div className="flex flex-row justify-center">
                    <img
                      alt="contact"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/headphone_icon.svg"
                      className="h-10 lg:h-16"
                    />
                  </div>
                  <div className="w-full md:w-4/5 space-y-3">
                    <div className="text-xl font-bold text-black text-center md:text-left">
                      {t('help_and_support')}
                    </div>
                    <div className=" text-gray-500  text-sm md:text-base text-center font-medium md:text-left">
                      {t('learn_more_about_mereos_and_its_solutions')}
                    </div>
                    <div className=" flex flex-row text-sm md:text-base justify-center md:justify-start items-baseline mt-1 space-x-2">
                      {" "}
                      <Link to="https://mereos.zendesk.com" className="text-yellow-500 font-semibold">
                        {t('frequently_asked_questions')}
                      </Link>{" "}
                      <img
                        src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                        alt="arrow down icon"
                        className="transform -rotate-90 w-3 m-0 p-0 -translate-y-0.5"
                      />
                    </div>
                  </div>
                </div>
              </div>
              
              <div style={{width:'50%'}}>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>

        <div className={`w-full ${classes.googlecontainer}`}>
          <div className="text-3xl md:text-5xl text-center  my-10 font-semibold">
            Nos bureaux
          </div>
          <div className="md:w-full h-96 relative flex flex-col justify-center items-center">
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "100%",
                }}
                center={{
                  lat: parseInt(cords.lat),
                    lng: parseInt(cords.lng),
                }}
                zoom={10}
                options={{
                  //make map gray
                  styles: mapStyles,
                  // disable default UI Icons
                  disableDefaultUI: true,
                }}
              >
                <Marker
                  position={{
                    lat: parseInt(cords.lat),
                    lng: parseInt(cords.lng),
                  }}
                  icon={{
                    url: "https://d2lxkizvrhu4im.cloudfront.net/icons/donut_icon.svg",
                    //resize image
                    scaledSize: new window.google.maps.Size(30, 30),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                  }}
                ></Marker>
              </GoogleMap>
            ) : (
              <></>
            )}
            {/* <img
              src="https://d2lxkizvrhu4im.cloudfront.net/images/map.svg"
              alt="map"
              className="my-0 w-full h-full object-fill"
            /> */}

            <div className="flex flex-col absolute md:top-20 md:right-28 md:flex-row items-center py-6 px-8  bg-white rounded-lg shadow-lg md:space-x-8">
              <div className="flex flex-row justify-center items-center ">
                <img
                  alt="contact"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/location_icon_with_bg.svg"
                  className="h-10 lg:h-16 p-0 m-0"
                />
              </div>
              <div className="w-full md:w-4/5 space-y-3">
                <div className="text-xl font-bold text-black text-center md:text-left">
                  {address.split(" ").splice(-1)}
                </div>
                <div className=" text-black  text-sm md:text-base text-center font-medium md:text-left">
                  {StringWithoutLastWord(address)}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="relative bg-black p-16 flex flex-row items-center justify-center my-0">
            <div className="absolute -top-14  bg-white w-11/12  lg:w-8/12 rounded-2xl shadow-lg">
              <div className="w-full  ">
                <nav
                  className={`w-full mx-auto flex flex-row list-none py-0  border-b-2 border-gray-300 justify-center`}
                >
                  <li className="mx-3 md:mx-auto text-center border-b-2 border-gray-300 hover:border-b-6 hover:border-yellow-300  py-4  my-0 text-gray-300 font-bold hover:text-black cursor-pointer  transform translate-y-0.5">
                    California, US
                  </li>

                  <li
                    className="mx-3 md:mx-auto text-center border-b-2
                  border-gray-300 hover:border-b-6 hover:border-yellow-300  py-4  my-0 text-gray-300 font-bold hover:text-black cursor-pointer  transform translate-y-0.5"
                  >
                    California, US
                  </li>

                  <li className="mx-3 md:mx-auto text-center border-b-2 border-gray-300 hover:border-b-6 hover:border-yellow-300  py-4  my-0 text-gray-300 font-bold hover:text-black cursor-pointer  transform translate-y-0.5">
                    California, US
                  </li>
                </nav>
              </div>
              <div className="flex flex-col md:flex-row    my-5 space-y-4 lg:space-y-0 items-start justify-around ml-10 md:mx-auto">
                <div className=" flex flex-row space-x-4 ">
                  <div className="">
                    <img 
                      alt="icons"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/location_icon.svg"
                      className="w-5"
                    />
                  </div>
                  <div className="w-full">
                    <div className="font-bold">Address</div>
                    <div className="text-sm">
                      3891 Ranchview Dr.Richardson,
                      <br /> California 62639
                    </div>
                  </div>
                </div>

                <div className=" flex flex-row space-x-4 ">
                  <div className="">
                    <img 
                      alt="icons"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/message_icon.svg"
                      className="w-5"
                    />
                  </div>
                  <div className="">
                    <div className="font-bold">Email</div>
                    <div className="text-sm">hello@forpeople.studio</div>
                  </div>
                </div>

                <div className=" flex flex-row space-x-4">
                  <div className="">
                    <img 
                      alt="icons"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/call_icon.svg"
                      className="w-5"
                    />
                  </div>
                  <div className="">
                    <div className="font-bold">Phone</div>
                    <div className="text-sm">+7-800-999-800</div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <ContactHBSPT /> */}
    </>
  );
};

export default Contact;
