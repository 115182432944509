import * as React from "react";

import ContactPage from "../components/contact/contact";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";

const Contact = () => {
  return (
    <>
      <Seo
        title="Contact"
        description="Des questions à poser ou des commentaires à partager?"
      />
      <Layout>
        <ContactPage />
      </Layout>
    </>
  )
}

export default Contact
